import {
  GatsbyImage as OriginalGatsbyImage,
  GatsbyImageProps as OriginalGatsbyImageProps,
} from 'gatsby-plugin-image';
import { Box, BoxProps } from '@chakra-ui/react';
import { IGatsbyImageData } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';

export type GatsbyImageProps = Omit<OriginalGatsbyImageProps, 'image'> &
  BoxProps & { image: IGatsbyImageData | undefined };

/**
 * Gatsby image
 */
const GatsbyImage = (props: GatsbyImageProps) => (
  <Box as={OriginalGatsbyImage} {...props} />
);

export default GatsbyImage;
