import { Box, Container, Heading, Text } from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from '../../basic/gatsbyImage';
import Section, { SectionProps } from '../../section';

const OurTeam = (props: SectionProps) => {
  const data: Queries.OurTeamImageQuery = useStaticQuery(graphql`
    query OurTeamImage {
      file(relativePath: { eq: "team/new.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1800, quality: 90)
        }
      }
    }
  `);

  return (
    <Section pt={20} pb={16} pos="relative" bg="gray.100" {...props}>
      <Container maxW={1800}>
        <Heading
          mb={12}
          lineHeight="short"
          as="h2"
          size="2xl"
          color="gray.700"
          textAlign="center"
        >
          <Text as="span" mb={0}>
            Unser{' '}
            <Box color="green.500" as="span" mb={0}>
              Team
            </Box>
          </Text>
          <Text
            fontSize="2xl"
            display="block"
            as="span"
            mb={0}
            color="gray.400"
          >
            Zuverlässig. Menschlich.
          </Text>
        </Heading>
        <GatsbyImage
          loading="lazy"
          shadow="lg"
          mx="auto"
          alt="Unser Team"
          image={data.file?.childImageSharp?.gatsbyImageData}
        />
      </Container>
    </Section>
  );
};

export default OurTeam;
