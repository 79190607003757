import React from 'react';
import {
  Box,
  Flex,
  Container,
  Heading,
  Text,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { ComponentWithChakraProps } from '../types';
import ContactFormModal from '../contactFormModal';

export type HeroProps = ComponentWithChakraProps;

const Hero: React.FC<HeroProps> = (props: HeroProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure({ id: 'contactForm' });

  return (
    <>
      <Box mb={10} {...props}>
        <Flex alignItems="center" minHeight="500px">
          <Container>
            <Box maxWidth={{ base: '100%', md: '70%', lg: '56%' }}>
              <Heading
                textStyle="h1"
                fontSize={{
                  base: '4xl',
                  lg: '5xl',
                }}
              >
                Holzbau und Zimmerei vom Fach
              </Heading>
              <Text
                color="gray.700"
                textStyle="p"
                fontSize={{
                  base: 'lg',
                  lg: 'xl',
                }}
                mb={5}
              >
                Wir stehen für energiesparendes und gesünderes Bauen im Alt- und
                Neubausektor. Bei Reparaturen von Balkonen bis zur
                schlüsselfertigen Erstellung eines mehrgeschossigen Holzhauses
                stehen wir Ihnen zur Verfügung.
              </Text>
              <Button
                onClick={onOpen}
                mr={3}
                size="lg"
                colorScheme="green"
                variant="solid"
              >
                Jetzt kontaktieren
              </Button>
              {/* <Button size="lg">Informationen</Button> */}
              {/* <Box */}
              {/*  mb={4} */}
              {/*  borderWidth={8} */}
              {/*  borderColor="gray.600" */}
              {/*  color="gray.600" */}
              {/*  borderStyle="solid" */}
              {/*  p={8} */}
              {/*  display="inline-block" */}
              {/*  width="100%" */}
              {/* > */}
              {/*  <Heading */}
              {/*    textTransform="uppercase" */}
              {/*    variant="h1" */}
              {/*    fontSize="5xl" */}
              {/*    fontWeight="700" */}
              {/*    mb={0} */}
              {/*  > */}
              {/*    Holzbau und */}
              {/*    <br /> */}
              {/*    Zimmerei */}
              {/*  </Heading> */}
              {/* </Box> */}
              {/* <Text color="gray.600" fontWeight={500} fontSize="xl"> */}
              {/*  <Text as="span" color="green.400"> */}
              {/*    Lokal. Umweltfreundlich. Energiesparend. */}
              {/*  </Text>{' '} */}
              {/*  Lorem ipsum dolor sit amet, consectetur adipisicing elit. */}
              {/* </Text> */}
              {/* <Button size="lg" variant="green"> */}
              {/*  Jetzt anfragen */}
              {/* </Button> */}
            </Box>

            {/*            <Box mt={12} opacity={0.6}>
              <Text align="center" color="#333" fontSize="sm">
                Diese Kunden vertrauen uns
              </Text>
              <Stack
                align="center"
                justifyContent="center"
                fontSize="xl"
                direction="row"
                spacing={5}
              >
                <Image height="1em" src={Company1} />
                <Image height="1em" src={Company2} />
                <Image height="1em" src={Company3} />
                <Image height="1em" src={Company4} />
                <Image height="1em" src={Company5} />
              </Stack>
            </Box> */}
          </Container>
        </Flex>
      </Box>
      <ContactFormModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default Hero;
