import React from 'react';

import Layout from '../components/layout';
import Seo from '../components/layout/seo';
import Hero from '../components/content/hero';
import WhatWeDo from '../components/content/WhatWeDoDark';
import AboutUs from '../components/content/AboutUs';
import OurTeam from '../components/content/OurTeam';

const IndexPage: React.FC<unknown> = () => (
  <Layout>
    <Seo />
    <Hero />
    <WhatWeDo />
    <AboutUs />
    <OurTeam />
  </Layout>
);

export default IndexPage;
