import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { graphql, useStaticQuery } from 'gatsby';
import Section, { SectionProps } from '../../section';
import ContactFormModal from '../../contactFormModal';
import GatsbyImage from '../../basic/gatsbyImage';

const AboutUs = (props: SectionProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure({ id: 'contactForm' });

  const data: Queries.AboutUsImageQuery = useStaticQuery(graphql`
    query AboutUsImage {
      file(relativePath: { eq: "work/2.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 1280, quality: 90)
        }
      }
    }
  `);

  return (
    <>
      <Section pb={24} bg="gray.50" pos="relative" {...props}>
        <Container>
          <Box maxW={{ lg: 'md', xl: 'xl' }} pt={{ base: 24, lg: 40 }} pb={12}>
            <Heading mb={4} as="h2" fontSize={{ base: '4xl', md: '6xl' }}>
              Lernen Sie{' '}
              <Box as="span" color="green.500">
                uns
              </Box>{' '}
              und{' '}
              <Box as="span" color="green.500">
                unsere Arbeit
              </Box>{' '}
              kennen
            </Heading>
            <Box mb={5} color="gray.600" fontSize={{ base: 'md', md: 'xl' }}>
              <Text>
                Die Zimmerei Schweizer & Speiser wurde im Jahr 2011 vom
                Zimmerermeister Stefan Schweizer und Zimmerer und Bautechniker
                Martin Speiser gegründet.
              </Text>
              <Text>
                Derzeit werden weitere 4 Zimmerergesellen und ein Auszubildender
                im 3. Lehrjahr beschäftigt.
              </Text>
            </Box>
            <Button
              minW={40}
              size="lg"
              bg="white"
              shadow="base"
              _hover={{ bg: 'green.500', color: 'white' }}
              onClick={() => onOpen()}
            >
              Jetzt anfragen
            </Button>
          </Box>
          <Box
            pos={{ lg: 'absolute' }}
            top={0}
            bottom={0}
            right={0}
            w={{
              base: 'full',
              lg: '50%',
            }}
            h={{ base: '600px', lg: 'full' }}
            clipPath={{ lg: 'polygon(8% 0%, 100% 0%, 100% 100%, 0% 100%)' }}
          >
            <GatsbyImage
              image={data.file?.childImageSharp?.gatsbyImageData}
              w="full"
              h="full"
              objectFit="cover"
              alt="Arbeiten am Dach"
            />
          </Box>
        </Container>
      </Section>
      <ContactFormModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default AboutUs;
