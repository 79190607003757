import {
  Box,
  Container,
  Heading,
  Icon,
  SimpleGrid,
  Text,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { GiBrickWall } from 'react-icons/gi';
import { FaCodepen, FaWrench } from 'react-icons/all';
import Section, { SectionProps } from '../../section';

export type ItemProps = {
  children: string;
  icon: IconType;
  title: string;
};

const Item = ({ children, icon, title }: ItemProps) => (
  <Box>
    <Icon mb={5} fontSize="4xl" color="green.400" as={icon} />
    <Heading mb={0} as="h3" fontSize="xl">
      {title}
    </Heading>

    <Box mt={2}>
      <Text lineHeight="tall" color="gray.400" mb={0}>
        {children}
      </Text>
    </Box>
  </Box>
);

const WhatWeDo = (props: SectionProps) => (
  <Section bg="gray.800" color="white" overflow="hidden" py={0} {...props}>
    <Container pt={24} pb={20}>
      <Heading mb={0} lineHeight="short" as="h2" size="2xl" maxW={800}>
        <Text display="block" as="span" mb={0} color="gray.100">
          Was wir anbieten
        </Text>
        <Text fontSize="2xl" display="block" as="span" mb={0} color="gray.400">
          Ergänzen, erweitern, erhalten - oder komplett neu?
        </Text>
      </Heading>
      <SimpleGrid
        mt={{ base: 12, lg: 20 }}
        columns={{
          base: 1,
          md: 3,
        }}
        spacing={{
          base: 12,
          md: 8,
          lg: 2,
        }}
        justifyContent={{
          lg: 'space-between',
        }}
      >
        <Item icon={GiBrickWall} title="Neubau">
          Wir bauen den Dachstuhl auf Ihr gemauertes Haus oder das ganze Haus
          aus Holz - von der Dacheindeckung bis zum Innenausbau.
        </Item>
        <Item icon={FaCodepen} title="Sanierung">
          Wir Sanieren und Dämmen Ihr bestehendes Dach oder die Fassade - auch
          landwirtschaftliche Gebäude mit Asbestwellplatten.
        </Item>
        <Item icon={FaWrench} title="Umbau">
          Wir erweitern ihr bestehendes Gebäude, bauen es um, bauen an oder
          stocken es auf.
        </Item>
      </SimpleGrid>
    </Container>
  </Section>
);

export default WhatWeDo;
